h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 3rem;
  line-height: 1.5;
}
.home {
  color: whitesmoke;
}

.bans {
  height: 100vh;
  background-color: #78b8b8;
  width: 100%;
}

.ban-1 {
  height: 100%;
  width: 100%;
  background-image: url('https://images.unsplash.com/photo-1595665593673-bf1ad72905c0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1628&q=80');
  background-position: bottom;
  display: flex;
  align-items: center;
}

.ban-t1 h2 {
  width: 300px;
  height: 100px;
}

.ban-t1 button {
  margin-top: 20px;
  color: black;
}
 
.ban-t1 {
  background-color: #2C3333;
  height: 200px;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20%;
  border-radius: 50px;
  opacity: 0.8;
}

.best-s {
  padding: 100px 10px 100px 10px;
  background-color: #4d4f4f;
  width: 100%;
  text-align: center;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.p-cats {
  padding: 100px 10px 100px 10px;
  background-color: #4d4f4f;
  width: 100%;
  text-align: center;
}

.fea-card {
  background-color: #4d4f4f;
  box-shadow: none;
  z-index: 0;
}

.card {
  z-index: 0;
}

.h-line {
  background-color: rgb(212, 210, 210);
  margin: 0;
  padding: 1rem;
}

@media (max-width: 500px) {
  .ban-t1 h2 {
    width: 200px;
    height: 100px;
  }

  .ban-t1 {
    margin: auto;;
  }
}

@media (max-width: 500px) {
  .ban-t1 {
    height: 200px;
    width: 250px;
  }
}



@media (max-width: 767px) {
  .carousel-inner .carousel-item > div {
      display: none;
  }
  .carousel-inner .carousel-item > div:first-child {
      display: block;
  }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

/* medium and up screens */
@media (min-width: 768px) {
  
  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(25%);
  }
  
  .carousel-inner .carousel-item-start.active, 
  .carousel-inner .carousel-item-prev {
    transform: translateX(-25%);
  }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start { 
transform: translateX(0);
}
.features {
  padding: 7% 15%;
  text-align: center;
  background-color: #fff;
  color: black;
  position: relative;
  z-index: 1;
}

.fea {
  text-align: center;
  padding-bottom: 10%;
}

.icon {
  color: #4d4f4f;
  margin-bottom: 5%;
}

.icon:hover {
  color: #5e6464;
}

#cta {
  background-color: #f1f0ef;
  ;
  padding: 7% 7%;
  text-align: center;
}

.find {
  color: #fff;
  font-weight: 900;
  font-size: 4rem;
  padding-bottom: 2rem;
  ;
}

.about {
  height: 20%;
}